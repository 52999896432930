import React from "react";
import "./index.scss";
import logo from "../../../Assets/logo.png";
import mail from "../../../Assets/mail.png";
import call from "../../../Assets/call.png";
import location from "../../../Assets/location.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  let nav = useNavigate();
  const goToPage = () => {
    nav(`/privacy-policy`);
  };
  const goToDeleteAccount = () => {
    nav(`/delete-account`);
  };
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-12 footerLogo">
            <img src={logo} height="64px" alt="" />
            <p className="info">
              Unlock Synergies with Network360 AI—Discover New Contacts Around
              You and Expand Your Influence.
            </p>
          </div>
          <div className="col-lg-5 col-12 links">
            <h3>Company</h3>
            <ul>
              <li>
                <Link
                  className="nav-link"
                  aria-current="page"
                  spy={true}
                  to="home"
                  smooth="true"
                  duration={500}
                  // offset={-150}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  aria-current="page"
                  spy={true}
                  to="socialMediaExperience"
                  smooth="true"
                  duration={500}
                  offset={-100}
                >
                  How it works
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  aria-current="page"
                  spy={true}
                  to="nextGeneration"
                  smooth="true"
                  duration={500}
                  offset={-100}
                >
                  Features
                </Link>
              </li>
              <li>
                <Link onClick={goToPage}>Privacy Policy</Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  aria-current="page"
                  spy={true}
                  to="whyChooseUs"
                  smooth="true"
                  duration={500}
                  offset={-100}
                >
                  Why Choose Us
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  aria-current="page"
                  spy={true}
                  to="whatClientsSay"
                  smooth="true"
                  duration={500}
                  offset={-100}
                >
                  Testimonials
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  aria-current="page"
                  spy={true}
                  to="downloadApp"
                  smooth="true"
                  duration={500}
                  offset={-100}
                >
                  Download App
                </Link>
              </li>
              <li>Terms & Conditions</li>
              <li>
                <Link onClick={goToDeleteAccount}>Delete Account</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-12 contactUs">
            <h3>Contact Us</h3>
            <ul>
              <li>
                <img src={mail} alt="" />
                network360ai@gmail.com
              </li>
              <li>
                <img src={call} alt="" />
                (832) 786 - 1872
              </li>
              <li>
                <img src={location} alt="" />
                29.74915°N,95.34804°W
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row copyright">
          <div className="col-lg-6 col-md-4 col-12">
            <div className="socialIcon">
              <div className="icon">
                <FaFacebookF />
              </div>
              <div className="icon">
                <FaTwitter />
              </div>
              <div className="icon">
                <FaLinkedinIn />
              </div>
              <div className="icon">
                <FaInstagram />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-8 col-12">
            <p>
              © 2024 All Right Reserved - <b>Network360ai</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
