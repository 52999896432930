import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import "animate.css";
import "react-datepicker/dist/react-datepicker.css";
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "react-checkbox-tree/lib/react-checkbox-tree.css";
// import HttpsRedirect from 'react-https-redirect';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
  </>
);
reportWebVitals();
