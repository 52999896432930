import React from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import CommonHeader from "../components/CommonHeader";

const DeleteAccount = () => {
  return (
    <>
      <CommonHeader />
      <section className="privacy-policy">
        <div className="topBanner">
          <div className="container">
            <h1>Delete Account</h1>
            <nav className="breadcrumb">
              <Link className="breadcrumb-item" to="/">
                Home
              </Link>
              <span className="breadcrumb-item active" aria-current="page">
                Delete Account
              </span>
            </nav>
          </div>
        </div>
        <div className="mainContent">
          <div className="container">
            <div className="deleteAccountBox">
              <form action="" className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Email</label>
                    <input
                      placeholder="Enter Here...."
                      type="email"
                      name=""
                      id=""
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Phone Number</label>
                    <input
                      placeholder="Enter Here...."
                      type="email"
                      name=""
                      id=""
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="">Reason</label>
                    <textarea
                      placeholder="Enter Here...."
                      rows={5}
                      className="form-control"
                    />
                  </div>
                  <p><b>Note :</b> Once you submit your delete account request, it will take 24 hours to process, and you will receive an update via email.</p>
                </div>
                <div className="col-md-12 d-flex align-items-center justify-content-end">
                  <button className="themeBtn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default DeleteAccount;
