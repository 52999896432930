import React, {useState } from "react";
import "./index.scss";
import logo from "../../../Assets/logo.png";
import { Link } from "react-router-dom";

const CommonHeader = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <nav className="navbar fixed-top navbar-expand-lg bg-body-tertiary">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img src={logo} height="64px" alt="" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarTogglerDemo01"
          aria-expanded={!isCollapsed}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${!isCollapsed ? "show" : ""}`}
          id="navbarTogglerDemo01"
        >
          <ul className="navbar-nav ms-auto">
            <li
              className={`nav-item`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                to="/"
              >
                Home
              </Link>
              <span className="activeIndicate"></span>
            </li>
            <li
              className={`nav-item`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                to="/"
              >
                How it works
              </Link>
              <span className="activeIndicate"></span>
            </li>
            <li
              className={`nav-item`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                to="/"
              >
                Features
              </Link>
              <span className="activeIndicate"></span>
            </li>
            <li
              className={`nav-item`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                to="/"
              >
                Why Choose Us
              </Link>
              <span className="activeIndicate"></span>
            </li>
            <li
              className={`nav-item`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                to="/"
              >
                Testimonials
              </Link>
              <span className="activeIndicate"></span>
            </li>
            <li
              className={`nav-item`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                to="/"
              >
                Download App
              </Link>
              <span className="activeIndicate"></span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default CommonHeader;
