import React, { useEffect, useState } from "react";
import "./index.scss";
import logo from "../../../Assets/logo.png";
import { Link, Events, scrollSpy } from "react-scroll";

const Header = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [activeSection, setActiveSection] = useState("home");

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
    // eslint-disable-next-line
  }, []);

  const setActiveFuncation = (data) => {
    setIsCollapsed(true);
    setActiveSection(data);
  };

  return (
    <nav className="navbar fixed-top navbar-expand-lg bg-body-tertiary">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logo} height="64px" alt="" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarTogglerDemo01"
          aria-expanded={!isCollapsed}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${!isCollapsed ? "show" : ""}`}
          id="navbarTogglerDemo01"
        >
          <ul className="navbar-nav ms-auto">
            <li
              className={`nav-item ${activeSection === "home" ? "active" : ""}`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                spy={true}
                to="home"
                smooth="true"
                duration={500}
                onSetActive={() => setActiveFuncation("home")}
                // offset={-150}
              >
                Home
              </Link>
              <span className="activeIndicate"></span>
            </li>
            <li
              className={`nav-item ${
                activeSection === "socialMediaExperience" ? "active" : ""
              }`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                spy={true}
                to="socialMediaExperience"
                smooth="true"
                duration={500}
                offset={-100}
                onSetActive={() => setActiveFuncation("socialMediaExperience")}
              >
                How it works
              </Link>
              <span className="activeIndicate"></span>
            </li>
            <li
              className={`nav-item ${
                activeSection === "nextGeneration" ? "active" : ""
              }`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                spy={true}
                to="nextGeneration"
                smooth="true"
                duration={500}
                offset={-100}
                onSetActive={() => setActiveFuncation("nextGeneration")}
              >
                Features
              </Link>
              <span className="activeIndicate"></span>
            </li>
            <li
              className={`nav-item ${
                activeSection === "whyChooseUs" ? "active" : ""
              }`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                spy={true}
                to="whyChooseUs"
                smooth="true"
                duration={500}
                offset={-100}
                onSetActive={() => setActiveFuncation("whyChooseUs")}
              >
                Why Choose Us
              </Link>
              <span className="activeIndicate"></span>
            </li>
            <li
              className={`nav-item ${
                activeSection === "whatClientsSay" ? "active" : ""
              }`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                spy={true}
                to="whatClientsSay"
                smooth="true"
                duration={500}
                offset={-100}
                onSetActive={() => setActiveFuncation("whatClientsSay")}
              >
                Testimonials
              </Link>
              <span className="activeIndicate"></span>
            </li>
            <li
              className={`nav-item ${
                activeSection === "downloadApp" ? "active" : ""
              }`}
            >
              <Link
                className="nav-link"
                aria-current="page"
                spy={true}
                to="downloadApp"
                smooth="true"
                duration={500}
                offset={-100}
                onSetActive={() => setActiveFuncation("downloadApp")}
              >
                Download App
              </Link>
              <span className="activeIndicate"></span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
