import React from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import CommonHeader from "../components/CommonHeader";

const PrivacyPolicy = () => {
  return (
    <>
      <CommonHeader />
      <section className="privacy-policy">
        <div className="topBanner">
          <div className="container">
            <h1>Privacy Policy</h1>
            <nav className="breadcrumb">
              <Link className="breadcrumb-item" to="/">
                Home
              </Link>
              <span className="breadcrumb-item active" aria-current="page">
                Privacy Policy
              </span>
            </nav>
          </div>
        </div>
        <div className="mainContent">
          <div className="container">
            <div className="info">
              <h2>Introduction</h2>
              <p>
                Welcome to the privacy policy of Renew360 ("we," "us," or
                "our"). We are committed to safeguarding your privacy and
                ensuring that your personal information is protected. This
                policy explains how we collect, use, and share data when you use
                our app.
              </p>
              <p>
                This privacy policy is available on our app’s store listing page
                and within the app itself, and it applies to the entity listed
                in the app's Google Play store as the developer. If you have any
                questions or concerns about our policy or your personal
                information, please contact us at: [your contact email].
              </p>
            </div>
            <div className="info">
              <h2>Information We Collect</h2>
              <ul>
                <li>
                  <b>Personal Information : </b>
                  <br /> We collect information you provide when you register
                  for the app, such as your name, email address, and other
                  profile details.
                </li>
                <li>
                  <b>Usage Data : </b>
                  <br /> We gather information about how you interact with the
                  app, such as the content you view, features you use, and the
                  time you spend using them.
                </li>
                <li>
                  <b>Device Information : </b>
                  <br /> We collect details about the device you use to access
                  Renew360, including its type, operating system, and unique
                  device identifiers.
                </li>
                <li>
                  <b>Location Data : </b>
                  <br /> If you enable location services, we may collect and use
                  your location information to enhance your app experience.
                  Specifically, we collect location data to display nearby users
                  and help you connect with others in your area. This data is
                  only collected with your consent when you enable location
                  services on your device.
                </li>
              </ul>
            </div>
            <div className="info">
              <h2>How We Use Your Information</h2>
              <ul>
                <li>
                  <b>Service Improvement : </b>
                  <br /> We use your data to provide, improve, and personalize
                  the app. This includes personalizing content recommendations,
                  improving AI algorithms, and ensuring smooth functionality
                  across devices.
                </li>
                <li>
                  <b>Nearby User Display : </b>
                  <br /> With your consent, location data may be used to show
                  you nearby users and enhance your connection experience within
                  the app.
                </li>
                <li>
                  <b>Communication : </b>
                  <br /> We may contact you with important updates, security
                  alerts, and account-related messages.
                </li>
                <li>
                  <b>Security & Legal Compliance : </b>
                  <br /> Your data is used to prevent unauthorized access and
                  ensure that we comply with applicable laws and regulations.
                </li>
              </ul>
            </div>
            <div className="info">
              <h2>Data Sharing & Security</h2>
              <p>
                We do not share your personal or sensitive information with
                third parties except as required by law, or when necessary to
                provide app services (e.g., to service providers who help
                operate our app). We ensure that any shared data is transmitted
                securely.
              </p>
            </div>
            <div className="info">
              <h2>Data Retention & Deletion Policy</h2>
              <p>
                We retain your personal information for as long as your account
                is active or as needed to provide you with the services. We may
                also retain and use your information as necessary to comply with
                legal obligations, resolve disputes, and enforce our agreements.{" "}
              </p>
              <p>
                You may request the deletion of your data by contacting us at:
                [redhorizon.llc@gmail.com]. Once the request is confirmed, your
                data will be permanently deleted within a reasonable time,
                unless it is required for legal or regulatory reasons.
              </p>
            </div>
            <div className="info">
              <h2>Privacy Policy Updates</h2>
              <p>We may update this policy from time to time. We will notify you of any significant changes through our app or by contacting you via email.</p>
            </div>
            <div className="info">
              <h2>Contact Information</h2>
              <p>For any inquiries about our privacy practices, please contact us at:</p>
              <p><b>Email :</b> <Link to="mailto:redhorizon.llc@gmail.com"> redhorizon.llc@gmail.com</Link></p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
