import React, { useRef } from "react";
import "./index.scss";
import Header from "../components/header";
import homeBanner from "../../Assets/homeBanner.png";
import appStore from "../../Assets/appStore.png";
import googleplay from "../../Assets/googleplay.png";
import comingsoon from "../../Assets/coming soon.png";
import Slider from "react-slick";
import Microsoft from "../../Assets/poweredBy/Microsoft.png";
import MongoDB_Logo from "../../Assets/poweredBy/MongoDB_Logo.png";
import OpenAI_Logo from "../../Assets/poweredBy/OpenAI_Logo.png";
import figma from "../../Assets/poweredBy/figma-5.png";
import Google_2015_logo from "../../Assets/poweredBy/Google_2015_logo.png";
import socketio_logo_icon from "../../Assets/poweredBy/socketio_logo_icon.png";
import createShare from "../../Assets/socialMediaExperience/createShare.png";
import explore from "../../Assets/socialMediaExperience/explore.png";
import discovered from "../../Assets/socialMediaExperience/discovered.png";
import nextGeneration from "../../Assets/nextGeneration.png";
import enhancedPrivacy from "../../Assets/enhancedPrivacy.png";
import logo from "../../Assets/logo.png";
import untitledcybersecurity from "../../Assets/untitled-cyber-security.png";
import insights from "../../Assets/insights.png";
import proximity from "../../Assets/proximity.png";
import searchuser from "../../Assets/search-user.png";
import profile from "../../Assets/profile.png";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import app1 from "../../Assets/app1.png";
import app2 from "../../Assets/app2.png";
import app3 from "../../Assets/app3.png";
import Footer from "../components/footer";
import testimonial1 from "../../Assets/testimonial1.jpeg";
import testimonial2 from "../../Assets/testimonial2.jpeg";
import testimonial3 from "../../Assets/testimonial3.jpeg";
import testimonial4 from "../../Assets/testimonial4.jpeg";
import { Link } from "react-router-dom";

function Home() {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const settings1 = {
    arrows: false,
    dots: false,
    infinite: false, // Disable infinite scroll to prevent looping
    speed: 500,
    slidesToShow: 2.5,
    swipeToSlide: true, // Show two and a half slides to partially cut off the third
    slidesToScroll: 1,
    centerMode: false, // Disable centering; we want a left-aligned display
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 425, // Mobile settings
        settings: {
          slidesToShow: 1, // Show one and a bit of the next slide on mobile
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Mobile settings
        settings: {
          slidesToShow: 2, // Show one and a bit of the next slide on mobile
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Tablet settings
        settings: {
          slidesToShow: 2.5, // Show 2.5 slides on larger screens
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sliderRef = useRef(null);
  const settings2 = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "200px",
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 425, // Mobile settings
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 768, // Mobile settings
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1025, // Tablet settings
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "100px",
        },
      },
    ],
  };
  const powered = [
    {
      img: Microsoft,
    },
    {
      img: MongoDB_Logo,
    },
    {
      img: OpenAI_Logo,
    },
    {
      img: figma,
    },
    {
      img: Google_2015_logo,
    },
    {
      img: socketio_logo_icon,
    },
  ];
  const firstColumn = {
    rtl: false,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
  };
  const secoundColumn = {
    rtl: true,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 3500,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
  };
  const thiredColumn = {
    rtl: false,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
  };
  const testimonial = [
    {
      img: testimonial4,
      name: "Jessica M.",
      desc:
        "“Network360AI has completely transformed how I manage my social media accounts. The real-time feedback and content recommendations have helped me stay ahead of trends, and I’ve seen a noticeable boost in my engagement. It’s like having a personal social media assistant!”",
    },
    {
      img: testimonial3,
      name: "Mark S.",
      desc:
        "“As a small business owner, I was struggling to keep up with social media, but Network360AI made everything easier. The platform’s insights into optimal posting times and content strategies have drastically improved my reach and interactions with customers.”",
    },
    {
      img: testimonial2,
      name: "Sarah L.",
      desc:
        "“I love how intuitive Network360AI is. The automated suggestions and easy-to-understand analytics have allowed me to fine-tune my social media presence without spending hours online. I’ve gained more followers and engagement since using it!”",
    },
    {
      img: testimonial1,
      name: "David K.",
      desc:
        "“What sets Network360AI apart is how personalized it feels. From the tailored content recommendations to the smart analytics, it’s helped me connect with my audience in a more meaningful way. My account has never been more active or engaging.”",
    },
  ];
  return (
    <>
      <Header />
      <section className="home" id="home">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="homeContent">
                <Link
                  className="homeBtn d-inline-block"
                  to="https://apps.apple.com/us/app/renew360/id6736639375"
                  target="_blank"
                >
                  ENTER
                </Link>
                <h1>
                  The <span>Future</span> of Social Connection with AI
                </h1>
                <p>
                  Unlock smarter, more secure social interactions with
                  Network360ai, powered by Renew360. Our intelligent platform
                  helps you connect, discover, and engage while keeping your
                  privacy protected. Whether you’re staying close to home or
                  looking to broaden your reach, Network360ai adapts to your
                  needs.
                </p>
                <h3>Available on App Store & Google Play</h3>
                <div className="btnGroup">
                  <Link
                    className="appBtn"
                    to="https://apps.apple.com/us/app/renew360/id6736639375"
                    target="_blank"
                  >
                    <img src={appStore} alt="" />
                  </Link>
                  <Link
                    className="appBtn"
                    to="https://play.google.com/store/apps/details?id=com.tech.renew360.app%27"
                    target="_blank"
                  >
                    <img src={comingsoon} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <img src={homeBanner} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="poweredby">
        <div className="container">
          <h3>Powered by:</h3>
          <Slider {...settings}>
            {powered?.map((item, i) => (
              <div className="item" key={i}>
                <img src={item?.img} className="m-auto m-md-0" alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className="socialMediaExperience" id="socialMediaExperience">
        <div className="row">
          <div className="col-xl-4">
            <h1>The Ultimate Social Media Experience</h1>
            <p>
              Revolutionize the way you handle social interactions with
              intelligent AI features that offer security, organization, and
              insights.
            </p>
          </div>
          <div className="col-xl-8">
            <Slider className="customeslider" {...settings1}>
              <div className="itemBox">
                <div className="icon">
                  <img src={createShare} className="img-fluid" alt="" />
                </div>
                <h3>Create and Share Instantly</h3>
                <p>
                  Post and engage with ease, using AI-powered tools that help
                  you create and manage content more efficiently.
                </p>
              </div>
              <div className="itemBox">
                <div className="icon">
                  <img src={explore} className="img-fluid" alt="" />
                </div>
                <h3>Explore Your Personalized Feed</h3>
                <p>
                  Our AI curates your feed based on your interests, preferences,
                  and local trends, giving you content that matters most.
                </p>
              </div>
              <div className="itemBox">
                <div className="icon">
                  <img src={discovered} className="img-fluid" alt="" />
                </div>
                <h3>Discoverable Mode: Expand Your Reach</h3>
                <p>
                  Activate Discoverable Mode to make new connections and explore
                  relevant content beyond your usual circles.{" "}
                </p>
              </div>
              <div className="itemBox">
                <div className="icon">
                  <img src={createShare} className="img-fluid" alt="" />
                </div>
                <h3>Create and Share Instantly</h3>
                <p>
                  Post and engage with ease, using AI-powered tools that help
                  you create and manage content more efficiently.
                </p>
              </div>
              <div className="itemBox">
                <div className="icon">
                  <img src={explore} className="img-fluid" alt="" />
                </div>
                <h3>Explore Your Personalized Feed</h3>
                <p>
                  Our AI curates your feed based on your interests, preferences,
                  and local trends, giving you content that matters most.
                </p>
              </div>
              <div className="itemBox">
                <div className="icon">
                  <img src={discovered} className="img-fluid" alt="" />
                </div>
                <h3>Discoverable Mode: Expand Your Reach</h3>
                <p>
                  Activate Discoverable Mode to make new connections and explore
                  relevant content beyond your usual circles.{" "}
                </p>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="nextGeneration one" id="nextGeneration">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>Next-Generation AI for Social Media Management</h1>
              <p>
                <i>Proximity-Based Content and Discoverable Mode</i>
              </p>
              <p>
                Whether you’re exploring your local community or looking to
                expand your network, Network360ai’s AI-powered Discoverable Mode
                lets you control how and when you’re visible to others.
              </p>
              <ul>
                <li>
                  <h3>Localized Recommendations</h3>
                  <p>
                    Stay updated with nearby events and trending content. Our AI
                    brings you closer to local happenings while keeping your
                    privacy secure.
                  </p>
                </li>
                <li>
                  <h3>Discoverable Mode: Be Seen, On Your Terms</h3>
                  <p>
                    Toggle on Discoverable Mode to increase your visibility
                    within your chosen community or interest groups, allowing
                    others to connect with you based on mutual interests or
                    proximity.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 text-center">
              <img src={nextGeneration} />
            </div>
          </div>
        </div>
      </section>
      <section className="nextGeneration second" id="nextGeneration">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-center">
              <img src={enhancedPrivacy} />
            </div>
            <div className="col-lg-6">
              <h1>Secure Messaging, Enhanced Privacy</h1>
              <p>
                Your conversations deserve to stay private, and our advanced
                messaging system ensures they do.
              </p>
              <ul>
                <li>
                  <h3>Private, Proximity Messaging</h3>
                  <p>
                    Connect securely with people nearby or across the globe,
                    knowing your conversations are encrypted end-to-end.
                  </p>
                </li>
                <li>
                  <h3>Enhanced Messaging Controls</h3>
                  <p>
                    Control how visible and accessible you are in chats with
                    Discoverable Mode—only engage when you choose to.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="whyChooseUs" id="whyChooseUs">
        <div className="container">
          <h1>Why Choose Network360ai?</h1>
          <p>
            With Network360ai, you get the perfect balance between personalized
            content, expanded visibility, and robust privacy protection.
          </p>
          <div className="circle-container">
            <div className="center-logo">
              <img src={logo} alt="Logo" className="logo" />
            </div>
            <div className="card top-left">
              <div className="icon">
                <img src={searchuser} alt="" />
              </div>
              <h3>Discoverable Mode for Expanded Connections</h3>
              <p>
                Enable Discoverable Mode when you want to broaden your network,
                whether to meet new people, discover events, or explore nearby
                communities.
              </p>
            </div>
            <div className="card top-right">
              <div className="icon">
                <img src={proximity} alt="" />
              </div>
              <h3>Proximity-Based Content</h3>
              <p>
                Get relevant recommendations for local events, trends, and
                people, ensuring you’re always in touch with what matters most
                in your area.
              </p>
            </div>
            <div className="card bottom-left">
              <div className="icon">
                <img src={insights} alt="" />
              </div>
              <h3>Real-Time Engagement Insights</h3>
              <p>
                Track how well your content performs and get real-time feedback,
                even as you expand your reach in Discoverable Mode.
              </p>
            </div>
            <div className="card bottom-right">
              <div className="icon">
                <img src={untitledcybersecurity} alt="" />
              </div>
              <h3>Privacy and Security, Always</h3>
              <p>
                From your newsfeed to your inbox, your data stays secure with
                advanced encryption and privacy-first settings.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="whatClientsSay" id="whatClientsSay">
        <div className="container">
          <div className="row">
            <div className="titleHed">
              <div>
                <h1>What Clients Say</h1>
                <p>
                  Discover what our clients have to say about their experience
                  with us.
                </p>
              </div>
              <div className="btnGroup">
                <button
                  className="themeBtn white"
                  onClick={() => sliderRef.current.slickPrev()}
                >
                  <RiArrowLeftLine />
                </button>
                <button
                  className="themeBtn"
                  onClick={() => sliderRef.current.slickNext()}
                >
                  <RiArrowRightLine />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <Slider className="customeslider" ref={sliderRef} {...settings2}>
            {testimonial?.map((data, i) => (
              <div className="box" key={i}>
                <div className="profile">
                  <img src={data?.img} alt="" />
                  <h5>{data?.name}</h5>
                </div>
                <p>{data?.desc}</p>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className="app" id="downloadApp">
        <div className="container">
          <div className="box">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="leftSide">
                  <h1>App available on Google Play and App Store</h1>
                  <p>
                    Get started with a seamless experience across all your
                    devices.
                  </p>
                  <div className="btnGroup">
                    <Link
                      className="appBtn"
                      to="https://apps.apple.com/us/app/renew360/id6736639375"
                      target="_blank"
                    >
                      <img src={appStore} alt="" />
                    </Link>
                    <Link
                      className="appBtn"
                      to="https://play.google.com/store/apps/details?id=com.tech.renew360.app%27"
                      target="_blank"
                    >
                      <img src={comingsoon} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="columnSlider">
                  <div className="w-100">
                    <Slider {...firstColumn}>
                      <div className="slide">
                        <img src={app1} alt="Slide 1" />
                      </div>
                      <div className="slide">
                        <img src={app2} alt="Slide 2" />
                      </div>
                      <div className="slide">
                        <img src={app3} alt="Slide 3" />
                      </div>
                      {/* Add more slides as needed */}
                    </Slider>
                  </div>
                  <div className="w-100">
                    <Slider {...secoundColumn}>
                      <div className="slide">
                        <img src={app1} alt="Slide 1" />
                      </div>
                      <div className="slide">
                        <img src={app2} alt="Slide 2" />
                      </div>
                      <div className="slide">
                        <img src={app3} alt="Slide 3" />
                      </div>
                      {/* Add more slides as needed */}
                    </Slider>
                  </div>
                  <div className="w-100">
                    <Slider {...thiredColumn}>
                      <div className="slide">
                        <img src={app1} alt="Slide 1" />
                      </div>
                      <div className="slide">
                        <img src={app2} alt="Slide 2" />
                      </div>
                      <div className="slide">
                        <img src={app3} alt="Slide 3" />
                      </div>
                      {/* Add more slides as needed */}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Home;
